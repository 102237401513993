import { Box, Typography } from '@mui/material';
import CustomButton from 'components/CustomButton';
import { SimpleTextField } from 'components/FormTextField';
import { useSnackbar } from 'contexts';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import React from 'react'
import { copyToClipboard } from 'utils';
import { generateSEPReportLink } from 'services';

const SEPReportLink = () => {
  const snackbar = useSnackbar();
  const [attemptId, setAttemptId] = React.useState("");
  const [reportLink, setReportLink] = React.useState("");

  const fetchReportLink = (e) => {
      e.preventDefault();

      generateSEPReportLink(attemptId)
      .then((data) => {
          setReportLink(data.link);
      })
      .catch((err) => snackbar.showSnackbar(err.message, "error"));
  }
  return (
    <Box width="50%" m="auto" mt={5}>
        <form onSubmit={fetchReportLink}>
            <Typography>Fetch report link -</Typography>

            <SimpleTextField
                placeholder="6428486e18089f25ee1fb806"
                name="attemptId"
                label="Attempt id"
                type="text"
                value={attemptId || ""}
                onChange={(e) => setAttemptId(e.target.value)}
                required
            />
            <br />

            <CustomButton variant="contained" type="submit" name="sep">
                Get Report link
            </CustomButton>
            {reportLink && (
                <>
                    <Box display="flex" alignItems="center" >
                        <SimpleTextField
                            readOnly
                            size='small'
                            variant="outlined"
                            fullWidth
                            value={reportLink}
                            spellCheck="false"
                            disabled
                        >
                        </SimpleTextField>
                        &nbsp;&nbsp;
                        <ContentCopyOutlinedIcon
                            fontSize="medium"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                copyToClipboard(reportLink);
                                snackbar.showSnackbar('copied!', 'info', true);
                            }}
                        />
                    </Box>
                </>
            )}
        </form>
        <br />

    </Box>
  )
}

export default SEPReportLink;