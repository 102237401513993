import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ContentHeader from 'components/ContentHeader';
import CustomButton from 'components/CustomButton';
import ClientSelect from 'components/ClientSelect';

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SimpleTextField } from 'components/FormTextField';
import { useSnackbar } from "contexts";
import { generateInprepAssessmentLink } from 'services';
import { copyToClipboard } from "utils";

function AssessmentLink(props) {
    const snackbar = useSnackbar();

    const [client, setClient] = React.useState();
    const [error, setError] = React.useState("");
    const [inputs, setInputs] = React.useState({});
    const [link, setLink] = React.useState("");
    const [localLink, setLocalLink] = React.useState("");

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    useEffect(() => {
        const queryUrl = link?.split('//')[1]?.split('/')[1];
        setLocalLink(`http://localhost:3000/${queryUrl}`);
    }, [link]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!client) {
            setError("Select client")
            return false;
        }

        generateInprepAssessmentLink({
            userEmail: inputs.email,
            assessmentId: inputs.assessmentId,
            redirectUrl: inputs.redirectUrl,
            clientId: client._id
        })
            .then((data) => {
                setLink(data.link);
            })
            .catch((err) => snackbar.showSnackbar(err.message, "error"));
    };

    return (
        <>
            <ContentHeader title="Generate Assessment Link" />
            <br />
            <br />
            <Box width="50%" m="auto">
                <form onSubmit={handleSubmit}>
                    <SimpleTextField
                        placeholder="john@gmail.com"
                        name="email"
                        label="Student Email"
                        type="email"
                        value={inputs.email || ""}
                        onChange={handleChange}
                        required
                    />
                    <br />
                    <br />
                    <SimpleTextField
                        placeholder="Assessment Id"
                        name="assessmentId"
                        label="Assessment Id"
                        type="text"
                        value={inputs.assessmentId || ""}
                        onChange={handleChange}
                        required
                    />
                    <br />
                    <br />

                    <ClientSelect
                        error={error === "Select client" ? error : ""}
                        defaultValue={client?._id}
                        onChange={(client) => setClient(client)}
                        sepClients={false}
                    />

                    <br />
                    <br />

                    <SimpleTextField
                        placeholder="Redirect URL"
                        name="redirectUrl"
                        label="Redirect URL"
                        type="text"
                        value={inputs.redirectUrl || ""}
                        onChange={handleChange}
                    />
                    <br />
                    <br />

                    <CustomButton variant="contained" type="submit">
                        Generate Link
                    </CustomButton>
                </form>
                <br />

            </Box>
            {link && (
                <>
                    <Box display="flex" alignItems="center" >
                        <SimpleTextField
                            readOnly
                            size='small'
                            variant="outlined"
                            fullWidth
                            value={link}
                            spellCheck="false"
                            disabled
                        >
                        </SimpleTextField>
                        &nbsp;&nbsp;
                        <ContentCopyOutlinedIcon
                            fontSize="medium"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                copyToClipboard(link);
                                snackbar.showSnackbar('copied!', 'info', true);
                            }}
                        />
                        <Link to={`/integrations/iframe?url=${link}`} style={{ textDecoration: "none", }} >
                            <Button
                                style={{ width: 160 }}
                                variant="contained"
                            >
                                View in IFrame
                            </Button>
                        </Link>
                    </Box>

                    <Box display="flex" alignItems="center" >
                        <SimpleTextField
                            readOnly
                            size='small'
                            variant="outlined"
                            fullWidth
                            value={localLink}
                            spellCheck="false"
                            disabled
                        >
                        </SimpleTextField>
                        &nbsp;&nbsp;
                        <ContentCopyOutlinedIcon
                            fontSize="medium"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                copyToClipboard(localLink);
                                snackbar.showSnackbar('copied!', 'info', true);
                            }}
                        />
                    </Box>
                </>
            )}
        </>
    );
}

export default AssessmentLink;