import Client from "./_client";

export const getUsers = async (userFilters = {}) => {
    let url = "/user?";

    Object.keys(userFilters).forEach((key) => {
        if (userFilters[key] && typeof userFilters[key] !== "object") url += `${key}=${encodeURIComponent(userFilters[key])}&`;
        else if (userFilters[key] && typeof userFilters[key] === "object") userFilters[key].forEach((el) => url += `${key}[]=${el}&`)
    });
    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get users. Please try again."
        );
    }

    return result?.data;
};

export const getUserById = async (userId, query = {}) => {
    let url = `/user/${userId}?`;

    Object.keys(query).forEach((key) => {
        if (query[key]) url += `${key}=${query[key]}&`;
    });

    let result = await new Client({
        path: url,
    }).get();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to get user. Please try again."
        );
    }

    return result.data;
}

export const saveUser = async (id, user) => {
    let result;
    if (id) {
        result = await new Client({
            path: `/user/${id}`,
            payload: user,
        }).put();
    } else {
        result = await new Client({
            path: `/user`,
            payload: user,
        }).post();
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save user. Please try again."
        );
    }

    return result.data;
}

export const saveClient = async (id, client) => {
    let result;
    if (id) {
        result = await new Client({
            path: `/client/${id}`,
            payload: client,
        }).put();
    } else {
        result = await new Client({
            path: `/client`,
            payload: client,
        }).post();
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save client. Please try again."
        );
    }

    return result.data;
}

export const deleteUserById = async (userId) => {
    const result = await new Client({
        path: `/user/${userId}`
    }).delete();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to delete user. Please try again."
        );
    }

    return result.data;
}

export const sendMailToClient = async (id, data) => {

    const result = await new Client({
        path: `/user/${id}/send-mail-to-client`,
        payload: data
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to send email. Please try again."
        );
    }

    return result.data;
}

export const updateProfileStatus = async (userId, newStatus) => {

    let result = await new Client({
        path: `/user/${userId}/profile-status`,
        payload: {
            profileStatus: newStatus,
            userId
        },
    }).put();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to update profile status. Please try again."
        );
    }

    return result.data;
}

export const generateMagicLink = async (
    { id, email, userName, userEmail, sep, templateId, batches, redirectUrl }
) => {
    let url = `/user/${id}/generate-link`;
    if (sep) url += "/sep";

    const payload = {
        email,
        templateId,
        user: {
            name: userName,
            email: userEmail,
        },
        batches
    }

    if (redirectUrl) payload['redirectUrl'] = redirectUrl;

    const result = await new Client({
        path: url,
        payload: payload,
    }).post();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to generate link!!"
        );
    }

    return result?.data;
}

export const generateInprepMagicLink = async (id, userEmail, clientEmail) => {

    let url = `/user/${id}/generate-link?`;
    url += `userEmail=${userEmail}&`
    url += `clientEmail=${clientEmail}&`
    url = encodeURI(url);
    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to generate link!!"
        );
    }

    return result?.data;
}

export const generateInprepAssessmentLink = async ({ clientId, userEmail, assessmentId, redirectUrl }) => {

    let url = `/user/${clientId}/generate-assessment-link?`;
    url += `userEmail=${userEmail}&`
    url += `assessmentId=${assessmentId}&`
    url += `redirectUrl=${redirectUrl}&`
    url = encodeURI(url);
    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to generate link!!"
        );
    }

    return result?.data;
}

export const generateMockMagicLink = async (
    { clientId, clientEmail, mockType, duration, user_email, user_name }
) => {
    let url = `/user/${clientId}/generate-link/mock`;

    const result = await new Client({
        path: url,
        payload: {
            email: clientEmail,
            mockType, duration, user_email, user_name
        },
    }).post();


    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to generate link!!"
        );
    }

    return result?.data;
}

export const generateSEPReportLink = async (attemptId) => {
    let url = `/sepAttempt/report-link?attemptId=${attemptId}`;

    const result = await new Client({
        path: url,
    }).get();


    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to generate link!!"
        );
    }

    return result?.data;
}

export const generateMockReportLink = async (
    { clientId, attemptId }
) => {
    let url = `/user/${clientId}/generate-report-link/mock`;

    const result = await new Client({
        path: url,
        payload: {
            attemptId
        },
    }).post();


    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to generate report link!!"
        );
    }

    return result?.data;
}

export const getClients = async (clientFilters = {}) => {
    let url = "/client?";

    Object.keys(clientFilters).forEach((key) => {
        if (clientFilters[key] !== undefined) url += `${key}=${clientFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get clients. Please try again."
        );
    }
    return result?.data;
};

export const createAmbassadorUser = async (data) => {
    data.role = 'AMBASSADOR_USER';

    const result = await new Client({
        path: `/signup`, payload: data,
    }).post();


    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save client. Please try again."
        );
    }

    return result.data;
};

export const getCreditByClientId = async (clientId) => {

    let url = '/credit?';
    url += `user=${clientId}`;

    const result = await new Client({
        path: url
    }).get();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get clients credits. Please try again."
        );
    }
    return result.data;
}

export const getMonthlyAnalyticsById = async (clientId, payload) => {

    let url = `analytics/monthly-analytics/${clientId}?`;

    if (payload.batches) {
        payload.batches.forEach(el => {
            url += `batches[]=${el._id}&`
        })
    }

    if (payload.months) {
        payload.months.forEach(el => {
            url += `months[]=${el}&`
        })
    } else if (payload.createdAtStart) {
        url += `createdAtStart=${payload.createdAtStart}&`;
        url += `createdAtEnd=${payload.createdAtEnd}&`;
    }

    const result = await new Client({
        path: url,
    }).get();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get clients credits. Please try again."
        );
    }
    return result.data;
}

export const addCreditToClient = async (payload) => {
    let url = `credit`;
    const result = await new Client({
        path: url,
        payload: payload,
    }).post();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to post clients credits. Please try again."
        );
    }

    return result.data;
}

export const updateClientCredits = async (id, payload) => {

    let result = await new Client({
        path: `/credit/${id}`,
        payload: payload,
    }).put();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to update Credit. Please try again."
        );
    }

    return result.data;
}

export const getInterviewPerformance = async (attemptId, summary, details) => {
    let url = `interviewAttempt/performance-admin`;

    const result = await new Client({
        path: url,
        payload: {
            "attemptId": attemptId,
            "include": {
                "summary": summary,
                "detailAnalysis": details
            }
        },
    }).post();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get interview Performance. Please try again."
        );
    }

    return result.data;
}

export const getTransactionOfClient = async (clientId) => {

    let url = 'transaction?';
    url += `user=${clientId}&`;

    let result = await new Client({ path: url, }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get transaction info. Please try again."
        );
    }

    return result.data.transactions;


}

export const getUsersCountForClient = async (clientId) => {

    const url = `user/students?client=${clientId}&deactivated=false`;
    let result = await new Client({
        path: url,
    }).get();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get student info. Please try again."
        );
    }
    return result?.data?.users?.length ?? 0;

}
export const onBoardUserAndGrantAccess = async (clientId, data) => {
    let url = `user/${clientId}/onboard`;

    const result = await new Client({
        path: url,
        payload: data,
    }).post();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get interview Performance. Please try again."
        );
    }

    return result.data;
}